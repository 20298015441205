
<template>
        <div>
            <div v-if="this.currentCustomerId !== null" class="row">
                <div class="col-lg-3 col-md-5">
                    <div v-if="!editTgl" class="box box-widget widget-user-2">
                        <!-- Add the bg color to the header using any of the bg-* classes -->
                        <div class="widget-user-header bg-default" :class="{'bg-blue': !editTgl}">
                            <h3 style="margin-left: 0px;" class="widget-user-username">                                 
                                <div class="row">
                                    <span class="col-xs-10">
                                        {{currentCustomer.company_name}}
                                    </span>
                                    <span class="col-xs-2" style="height: 100%">
                                        <i class="fa fa-address-book"></i>
                                    </span>
                                </div>
                            </h3>
                            <h5 class="widget-user-desc" style="margin-left: 0px;" @click="makeCurrentMnt(currentCustomer.contactid)"><span style="cursor: pointer">{{currentCustomer.contact_person.fullname}}</span></h5>
                        </div>
                    </div>
                    <div  class="box box-widget">
                        <div v-if="editTgl" class="box-header" style="padding-bottom: 0px">
                            <div class="form-group" :class="{'has-error': $v.formResponses.company_name.$error}" style="padding-left: 0px">
                                <input id="inputName" class="form-control input-lg"  v-model="$v.formResponses.company_name.$model" type="text">
                                <span v-if="!$v.formResponses.company_name.required" class="help-block">Povinný údaj</span>
                                <span v-if="!$v.formResponses.company_name.maxLength" class="help-block">Může mít maximálně 200 znaků!</span>
                            </div>  
                        </div>
                        <div v-if="!editTgl" class="box-header no-border">
                            <span class="text-muted" style="margin-left: 8px;">Evidováno {{getDate(currentCustomer.registered)}}</span>
                            <div class="pull-right">
                                <button @click="editCustomer()" type="button" class="btn btn-default" style="border: none; margin-left: 10px"><span><i class="fa fa-edit"></i> Upravit</span></button>
                            </div>
                        </div>
                        <div class="box-footer no-padding">
                            <ul class="nav nav-stacked">
                                <li v-if="editTgl">                                    
                                    <a v-if="editTgl" href="#"  @click="(e) => e.preventDefault()">
                                        <div class="row">
                                            <span class="col-xs-5">Kontaktní osoba</span>
                                            <span class="col-xs-7">
                                                <select class="form-control input-sm col-xs-6" v-model="$v.formResponses.contactid.$model">
                                                    <option v-for="mnt in minMaintainers" :key="mnt.id" :value="mnt.id">{{mnt.text}}</option>
                                                </select>
                                            </span>
                                         </div>
                                    </a>
                                </li>

                                <li v-if="currentCustomer.contact_person.email && !editTgl">
                                    <a  style="cursor: auto"  @click="(e) => e.preventDefault()">Email <span class="pull-right">{{currentCustomer.contact_person.email}}</span></a>
                                </li>

                                <li v-if="currentCustomer.contact_person.phone && !editTgl">

                                    <a  style="cursor: auto"  @click="(e) => e.preventDefault()">Telefon <span class="pull-right">{{currentCustomer.contact_person.phone}}</span></a>
                                </li>

                                <li v-if="editTgl">
                                    <a href="#"  @click="makeCurrentMnt(formResponses.contactid)">Email a telefon kontaktní osoby upravujte <a href="#">zde</a></a>
                                </li>

                                <li v-if="currentCustomer.city && !editTgl">                                    
                                    <a href="#" @click="(e) => e.preventDefault()">
                                        <!-- <strong><i class="fa fa-map-marker"></i> Adresa:</strong> -->
                                        <span class="text-muted"><span v-if="currentCustomer.street">{{currentCustomer.street}}, </span>{{currentCustomer.city}}<span v-if="currentCustomer.zip">, {{currentCustomer.zip}}</span></span>
                                    </a>
                                </li>

                                <li v-if="editTgl">                                    
                                    <a href="#"  @click="(e) => e.preventDefault()">
                                        <div class="row">
                                            <span class="col-xs-5">Fakturační osoba</span>
                                            <span class="col-xs-7">
                                                <select class="form-control input-sm col-xs-6" v-model="$v.formResponses.invoiceid.$model">
                                                    <option v-for="mnt in minMaintainers" :key="mnt.id" :value="mnt.id">{{mnt.text}}</option>
                                                </select>
                                            </span>
                                         </div>
                                    </a>
                                </li>

                                <li v-if="formResponses.invoiceid != oldinvoiceid && formResponses.invoiceid && editTgl">
                                    <a href="#" @click="makeCurrentMnt(formResponses.invoiceid)">Email a telefon fakturační osoby upravujte <a href="#">zde</a></a>
                                </li>

                                <li v-if="editTgl">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">IČO</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.ico.$error}" style="margin-bottom: 0px">
                                                <input type="text" class="form-control input-sm" id="inputICO" v-model="$v.formResponses.ico.$model">
                                                <span v-if="!$v.formResponses.ico.minLength" class="help-block">Musí mít minimálně 7 znaků!</span>
                                                <span v-if="!$v.formResponses.ico.maxLength" class="help-block">Může mít maximálně 9 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.ico}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                                <li v-if="editTgl">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">DIČ</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.dic.$error}" style="margin-bottom: 0px">
                                                <input type="text" class="form-control input-sm" id="inputDIC" v-model="$v.formResponses.dic.$model">
                                                <span v-if="!$v.formResponses.dic.minLength" class="help-block">Musí mít minimálně 8 znaků!</span>
                                                <span v-if="!$v.formResponses.dic.maxLength" class="help-block">Může mít maximálně 12 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.dic}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                                <li v-if="editTgl">
                                    <a href="#">
                                        Fakturační adresa stejná jako korespondenční
                                        <input type="checkbox" class="pull-right" v-model="sameAsAddress">
                                    </a>
                                </li>
                                <li v-if="editTgl">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Ulice</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.street.$error}" style="margin-bottom: 0px">
                                                <input type="text" class="form-control input-sm" v-model="$v.formResponses.street.$model">
                                                <span v-if="!$v.formResponses.street.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.street}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                                <li v-if="editTgl">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Město</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.city.$error}" style="margin-bottom: 0px">
                                                <input type="text" class="form-control input-sm" v-model="$v.formResponses.city.$model">
                                                <span v-if="!$v.formResponses.city.required" class="help-block">Povinný údaj</span>
                                                <span v-if="!$v.formResponses.city.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.city}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                                <li v-if="editTgl">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">PSČ</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.zip.$error}" style="margin-bottom: 0px">
                                                <input class="form-control input-sm"  v-model="$v.formResponses.zip.$model" type="number">
                                                <span v-if="!$v.formResponses.zip.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                                <span v-if="!$v.formResponses.zip.maxLength" class="help-block">Může mít maximálně 6 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.zip}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                                <li v-if="editTgl && !sameAsAddress">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Ulice (fakturační)</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.invoice_street.$error}" style="margin-bottom: 0px">
                                                <input type="text" class="form-control input-sm" v-model="$v.formResponses.invoice_street.$model">
                                                <span v-if="!$v.formResponses.invoice_street.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.invoice_street}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                                <li v-if="editTgl && !sameAsAddress">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Město (fakturační)</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.invoice_city.$error}" style="margin-bottom: 0px">
                                                <input type="text" class="form-control input-sm" v-model="$v.formResponses.invoice_city.$model">
                                                <span v-if="!$v.formResponses.invoice_city.required" class="help-block">Povinný údaj</span>
                                                <span v-if="!$v.formResponses.invoice_city.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.invoice_city}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                                <li v-if="editTgl && !sameAsAddress">
                                    <a href="#" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">PSČ (fakturační)</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.invoice_zip.$error}" style="margin-bottom: 0px">
                                                <input class="form-control input-sm"  v-model="$v.formResponses.invoice_zip.$model" type="number">
                                                <span v-if="!$v.formResponses.invoice_zip.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                                <span v-if="!$v.formResponses.invoice_zip.maxLength" class="help-block">Může mít maximálně 6 znaků!</span>
                                            </div>
                                            <!-- <span v-else class="pull-right">{{currentCustomer.invoice_zip}}</span> -->
                                        </span>
                                    </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div v-if="!editTgl && currentCustomer.invoiceid" class="box-footer" style="padding-left: 18px">
                            <strong>Fakturační údaje: </strong>
                            <p>
                                <a href="#" @click="makeCurrentMnt(currentCustomer.invoiceid)"><i class="fa fa-user"></i> {{currentCustomer.invoice_person.fullname}}</a><br>
                                <span class="text-muted" v-if="currentCustomer.ico">IČO: {{currentCustomer.ico}}<br></span> 
                                <span class="text-muted" v-if="currentCustomer.dic">DIČ: {{currentCustomer.dic}}<br></span> 
                                <a v-if="currentCustomer.invoice_person.email" :href="'mailto:' + currentCustomer.invoice_person.email"><i class="fa fa-envelope-o"></i> {{currentCustomer.invoice_person.email}}<br></a>
                                <a v-if="currentCustomer.invoice_person.phone" :href="'tel:' + currentCustomer.invoice_person.phone"><i class="fa fa-phone"></i> {{currentCustomer.invoice_person.phone}}<br></a>
                                <span v-if="!sameAsAddress" class="text-muted"><i class="fa fa-map-marker"></i> <span v-if="currentCustomer.invoice_street">{{currentCustomer.invoice_street}}, </span>{{currentCustomer.invoice_city}}<span v-if="currentCustomer.invoice_zip">, {{currentCustomer.invoice_zip}}</span></span>
                            </p>

                        </div>

                        <div v-if="editTgl" class="box-footer" style="padding-top: 10px">
                            <button type="button" class="btn btn-success" @click="cusUpdate()" :disabled="$v.formResponses.$invalid">Uložit změny</button> <br>
                            <div style="margin-top: 10px">
                                <button type="button" class="btn btn-danger btn-sm" @click="cusDelete()">Odstranit záznam</button>
                                <button type="button" class="btn btn-default btn-sm pull-right" @click="editTgl = false">Zrušit úpravy</button>
                            </div>
                        </div>
                    </div>

                    <!-- /.box -->
                </div>
                <!-- /.col -->
                <div class="col-lg-9 col-md-7">
                <div class="nav-tabs-custom">
                    <ul class="nav nav-tabs">

                        <li class="active"><a href="#issues_sub" data-toggle="tab"><i class="fa fa-tasks"></i> Úkony</a></li>
                        <li><a href="#cars_sub" data-toggle="tab"><i class="fa fa-car"></i> Auta</a></li>
                        <li><a href="#driver_sub" data-toggle="tab"><i class="fa fa-users"></i> Osoby</a></li>
                    </ul>
                    <div class="tab-content">
                    <div class="active tab-pane" id="issues_sub">
                        <div class="row">
                            <div class="col-lg-4" style="margin-top: 5px">
                                <div class="input-group input-group-sm" style="width: 100%">
                                <!-- <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat"> -->
                                        <input v-model="filter" @input="onFilterTextBoxChanged()" type="text" name="table_search" class="form-control pull-right" placeholder="Hledat">
                                </div>
                            </div>
                            <div class="col-lg-4" style="margin-top: 5px">
                                <div class="input-group input-group-sm" style="width: 100%">          
                                    <!-- <div class="input-group-btn" style="margin-right: 5px"> -->
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 1, 'btn-primary': showByStage == 1}" @click="showByStage = 1">Plánováno</button>
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 4, 'btn-primary': showByStage == 4}" @click="showByStage = 4">Otevřeno</button>
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 3, 'btn-primary': showByStage == 3}" @click="showByStage = 3">Uzavřeno</button>
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 0, 'btn-primary': showByStage == 0}" @click="showByStage = 0">Všechny</button>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <div class="col-lg-4" style="margin-top: 5px">
                                <div class="pull-right">
                                    <router-link :to="{ name: 'IssueAdd', params: { customerid: currentCustomer.customerid } }" class="btn btn-default btn-sm btn-flat pull-right"><i class="fa fa-plus"></i> Přidat úkon</router-link>
                                    <div class="input-group pull-right" style="margin-right: 5px">
                                    <button type="button" class="btn btn-sm btn-flat btn-default dropdown-toggle pull-right" data-toggle="dropdown" aria-expanded="true">Možnosti tabulky
                                        <span class="fa fa-caret-down"></span></button>
                                    <ul class="dropdown-menu">
                                        <li><span style="margin-left: 15px">Zobrazit:</span></li>
                                        <li v-for="(col, index) in columnDefs" :key="index"><label><input v-model="hideCol[index]" @change="columnHide(index)" type="checkbox" style="margin-left: 20px; margin-right: 5px;">{{col.headerName}}</label></li>
                                        <li class="divider"></li>
                                        <li v-if="shrinked"><a href="#" @click="(e) => {e.preventDefault(); fillWindowCols()}">Roztáhnout sloupce</a></li>
                                        <li v-else><a href="#" @click="(e) => {e.preventDefault(); shrinkCols()}">Srazit sloupce</a></li>
                                    </ul>
                                    <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div v-if="!currentCustomer.issues" class="callout callout-info">
                            <p><i class="icon fa fa-info"></i> U tohoto zákazníka nejsou žádné Úkony.</p>                          
                        </div>
                        <!-- <ag-grid-vue v-else-if="rowData !== null"
                                :style="`width: auto; height: ${windowHeight-300}px`"
                                class="ag-theme-balham"

                                :gridOptions="gridOptions"
                                :columnDefs="columnDefs"
                                :rowData="rowData.filter((item) => item.tagid == showByStage || showByStage == 0 || (showByStage == 2 && item.tagid != 1 && item.tagid != 3))"
                                @cellClicked="cellClick"
                        ></ag-grid-vue> -->
                        
                            <div v-if="currentCustomer.issues != ''" class="table-responsive">
                                <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th style="width: 10%">Stav</th>
                                    <th style="width: 40%">Název</th>
                                    <th style="width: 15%">Od</th>
                                    <th>Do</th>
                                    <th>Auto</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="issue in issueRows.filter((item) => item.tagid == showByStage || showByStage == 0 || (showByStage == 0 && item.tagid != 1 && item.tagid != 3))" :key="issue.issueid">
                                        <td><span class="label" :style="`background-color: ${issue.tag_color};`">{{issue.tag_title}}</span></td>
                                        <td><a @click="makeCurrentIssue(issue.issueid)">{{issue.name}}</a></td>
                                        <td>{{fixCorruptedTime(issue.due_from)}}</td>
                                        <td>{{fixCorruptedTime(issue.due_to)}}</td>
                                        <td><a @click="makeCurrentCar(issue.carid)">{{issue.car_name}}</a></td>
                                    </tr>
                                </tbody>
                                </table>    
                            </div>

                    </div>
                    <!-- /.tab-pane -->
                    <div class="tab-pane" id="cars_sub">
                        <div class="row">
                            <div class="col-lg-4" style="margin-top: 5px">
                                <div class="input-group input-group-sm" style="width: 100%">
                                        <input v-model="filterCars" type="text" name="table_search" class="form-control" placeholder="Hledat">
                                </div>
                            </div>
                            <div class="col-lg-4" style="margin-top: 5px">
                            </div>
                            <div class="col-lg-4" style="margin-top: 5px">
                                <div class="pull-right" style="margin-right: 5px">
                                    <router-link :to="{ name: 'CarAdd', params: { customerid: currentCustomer.customerid } }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat auto</router-link>
                                    <div class="input-group pull-right" style="margin-right: 5px">
                                    <button type="button" class="btn btn-sm btn-flat btn-default dropdown-toggle pull-right" data-toggle="dropdown" aria-expanded="true">Možnosti tabulky
                                        <span class="fa fa-caret-down"></span></button>
                                    <ul class="dropdown-menu">
                                        <li><span style="margin-left: 15px">Zobrazit:</span></li>
                                        <li v-for="(val, key) in carTableConf" :key="key"><label style="font-weight: normal;"><input v-model="carTableConf[key].state" type="checkbox" style="margin-left: 20px; margin-right: 5px;">{{val.title}}</label></li>
                                    </ul>
                                    <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div v-if="currentCustomer.cars != ''" :style="`width: auto; height: ${windowHeight-300}px; overflow: auto`">
                            <table id="bigTable" class="table table-hover table-responsive">
                                <thead>
                                <tr>
                                    <th v-if="carTableConf.color.state" style="width: 10px"></th>
                                    <th>Název</th>
                                    <th v-if="carTableConf.fullname.state">Řidič</th>
                                    <th v-if="carTableConf.last_issue.state">Poslední úkon</th>
                                    <th v-if="carTableConf.next_issue.state">Následující úkon</th>
                                    <th v-if="carTableConf.vin_code.state">VIN</th>
                                    <th v-if="carTableConf.licence_plate.state">SPZ</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="car in carsRows" :key="car.carid">
                                        <td v-if="carTableConf.color.state"><span class="label" :style="`border: solid 1px #EEEFF4;background-color: ${car.color}; color: ${car.color}`">_</span></td>
                                        <td><a @click="makeCurrentCar(car.carid)">{{car.name}}</a></td>
                                        <td v-if="carTableConf.fullname.state"><a @click="makeCurrentMnt(car.maintainerid)">{{car.fullname}}</a></td>
                                        <td v-if="carTableConf.last_issue.state"><a @click="makeCurrentIssue(car.last_issueid)">{{car.last_issue}}</a></td>
                                        <td v-if="carTableConf.next_issue.state"><a @click="makeCurrentIssue(car.next_issueid)">{{car.next_issue}}</a></td>
                                        <td v-if="carTableConf.vin_code.state">{{car.vin_code}}</td>
                                        <td v-if="carTableConf.licence_plate.state">{{car.licence_plate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div v-else class="callout callout-info">
                            <p><i class="icon fa fa-info"></i> U tohoto zákazníka nejsou evidována žádná auta.</p>                          
                        </div>
                    </div>
                    <!-- /.tab-pane -->

                    <div class="tab-pane" id="driver_sub">
                        <div class="row">
                            <div class="col-lg-4" style="margin-top: 5px">
                                <div class="input-group input-group-sm" style="width: 100%">
                                <!-- <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat"> -->
                                        <input v-model="filterMnts" @input="onMntFilterTextBoxChanged()" type="text" name="table_search" class="form-control pull-right" placeholder="Hledat">
                                </div>
                            </div>
                            <div class="col-lg-4" style="margin-top: 5px">
                            </div>
                            <div class="col-lg-4" style="margin-top: 5px">
                                <div class="pull-right">
                                    <router-link :to="{ name: 'MaintainerAdd', params: { customerid: currentCustomer.customerid } }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-user-plus"></i> Přidat osobu</router-link>
                                    <div class="input-group pull-right" style="margin-right: 5px">
                                    <button type="button" class="btn btn-sm btn-flat btn-default dropdown-toggle pull-right" data-toggle="dropdown" aria-expanded="true">Možnosti tabulky
                                        <span class="fa fa-caret-down"></span></button>
                                    <ul class="dropdown-menu">
                                        <li><span style="margin-left: 15px">Zobrazit:</span></li>
                                        <li v-for="(val, key) in personTableConf" :key="key"><label><input v-model="personTableConf[key].state" @change="mntColumnHide(index)" type="checkbox" style="margin-left: 20px; margin-right: 5px;">{{val.title}}</label></li>
                                        <li class="divider"></li>
                                    </ul>
                                    <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>

                        <!-- <ag-grid-vue v-if="mntRowData !== null"
                                :style="`width: auto; height: ${windowHeight-300}px`"
                                class="ag-theme-balham"

                                :gridOptions="mntGridOptions"
                                :columnDefs="mntColumnDefs"
                                :rowData="mntRowData"
                                @cellClicked="cellClick"
                        ></ag-grid-vue> -->
                        <div v-if="currentCustomer.maintainers != ''" :style="`width: auto; height: ${windowHeight-300}px; overflow: auto`">
                            <table class="table table-hover table-responsive">
                                <thead>
                                <tr>
                                    <th v-if="personTableConf.fullname.state">Jméno</th>
                                    <th v-if="personTableConf.last_issue.state">Poslední úkon</th>
                                    <th v-if="personTableConf.next_issue.state">Následující úkon</th>
                                    <th v-if="personTableConf.phone.state">Telefon</th>
                                    <th v-if="personTableConf.email.state">Email</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="maintainer in currentCustomer.maintainers" :key="maintainer.maintainerid">
                                        <td v-if="personTableConf.fullname.state"><a @click="makeCurrentMnt(maintainer.maintainerid)">{{maintainer.fullname}}</a></td>
                                        <td v-if="personTableConf.last_issue.state"><a>{{maintainer.last_issue}}</a></td>
                                        <td v-if="personTableConf.next_issue.state"><a>{{maintainer.next_issue}}</a></td>
                                        <td v-if="personTableConf.phone.state"><a>{{maintainer.phone}}</a></td>
                                        <td v-if="personTableConf.email.state"><a>{{maintainer.email}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        
                        <!-- <div>
                            <div class="input-group input-group-sm pull-right">                                  
                                    <div class="input-group-btn pull-right" style="margin-right: 5px">
                                        <router-link :to="{ name: 'MaintainerAdd', params: { customerid: currentCustomer.customerid } }" class="btn btn-default btn-flat pull-right"><i class="fa fa-user-plus"></i> Přidat osobu</router-link>
                                    </div>
                            </div>
                            <div class="input-group input-group-sm">
                                <input v-model="filterMnts" type="text" name="table_search" class="form-control" style="width: 300px" placeholder="Hledat">
                            </div>
                        </div>
                        <br>
                        <div class="box-body">
                            <ul class="products-list product-list-in-box">
                                <li class="item" v-for="maintainer in mntsRows" :key="maintainer.maintainerid">
                                <div class="product-img">
                                    <a href="#" @click="makeCurrentMnt(maintainer.maintainerid)"><img class="img-circle img-bordered-sm" src="../assets/img/user1-128x128.jpg" alt="user image"></a>
                                </div>
                                <div class="product-info">
                                    <a href="#" class="product-title" @click="makeCurrentMnt(maintainer.maintainerid)">{{maintainer.fullname}}
                                    <span class="product-description">
                                        <i v-if="maintainer.canlog" class="fa fa-key"></i>  <span v-if="maintainer.admin == 0">Řidič</span>
                                        <span v-if="maintainer.admin == 1">Admin</span>
                                        <span v-if="maintainer.admin == 2">Accountant</span>
                                    </span>
                                </div>
                                </li>                                
                            </ul>
                        </div> -->
                            
                    </div>
                    <!-- /.tab-pane -->
                    </div>
                    <!-- /.tab-content -->
                </div>
                <!-- /.nav-tabs-custom -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
</template>

<script>
const name = 'CustomerDetail';

import router from '../router';

// Vuelidate
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'

import { mapGetters, mapActions } from "vuex";

//import { AgGridVue } from 'ag-grid-vue';

export default {
    name,
    components: {
     //   AgGridVue
    },
    
    data() {
        return {
            page: null,
            windowHeight: window.innerHeight,

            // Variables for edit
            formResponses: {
                invoiceid: null,
                contactid: null,
                company_name: '',
                ico: '',
                dic: '',
                invoice_street: '',
                invoice_city: '',
                invoice_zip: '',
                invoice_country: '',
                street: '',
                city: '',
                zip: '',
                country: ''
            },
            oldinvoiceid: null,

            // Toggles
            editTgl: false,
            sameAsAddress: false,
            showByStage: 0,
            shrinked: false,
            mntShrinked: false,

            // Datatable options
            filter: '',
            filterCars: '',
            filterMnts: '',

            columnDefs: null,
            rowData: null,
            gridOptions: null,
            hideCol: [
                true,
                true,
                true,
                true,
                false,
                false,
                false
            ],

            mntColumnDefs: null,
            mntRowData: null,
            mntGridOptions: null,
            mntHideCol: [
                true,
                true,
                true,
                false,
                false
            ],

            carTableConf: {
                color: { state: true, title: "Barva" },
                fullname: { state: false, title: "Řidič" },
                vin_code: { state: true, title: "VIN" },
                licence_plate: { state: true, title: "SPZ" },
                last_issue: { state: true, title: "Poslední úkon" },
                next_issue: { state: true, title: "Další úkon" }
            },
            personTableConf: {
                fullname: { state: true, title: "Jméno" },
                last_issue: { state: true, title: "Poslední úkon" },
                next_issue: { state: true, title: "Další úkon" },
                phone: {state: true, title: "Telefon"},
                email: {state: true, title: "Email"}
            }
        }
    },
    validations: {
        formResponses: {
            invoiceid: {},
            contactid: {},
            company_name: {
                required,
                maxLength: maxLength(200)
            },
            ico: {
                minLength: minLength(7),
                maxLength: maxLength(9)
            },
            dic: {
                minLength: minLength(8),
                maxLength: maxLength(12)
            },
            invoice_street: {
                maxLength: maxLength(32),
            },
            invoice_city: {
                maxLength: maxLength(32),
                required: requiredIf(function() {
                    return this.formResponses.invoice_street || this.formResponses.invoice_zip;
                })
            },
            invoice_zip: {
                minLength: minLength(5),
                maxLength: maxLength(6)
            },
            invoice_country: {
            },
            street: {
                maxLength: maxLength(32),
            },
            city: {
                maxLength: maxLength(32),
                required: requiredIf(function() {
                    return this.formResponses.street || this.formResponses.zip;
                })
            },
            zip: {
                minLength: minLength(5),
                maxLength: maxLength(6)
            },
            country: {
            }
        }
    },
    computed: {
        ...mapGetters(["getAllPages", 'currentCustomer', 'currentCustomerId', 'minMaintainers']),

        carsRows () {
            if (!this.currentCustomer.cars.length) {
                return []
            }
            return this.currentCustomer.cars.filter(item => {
                return (this.$helpers.filterCheck(this.filter, item.name) || this.$helpers.filterCheck(this.filter, item.licence_plate) || this.$helpers.filterCheck(this.filter, item.vin_code));
            })
        },
        console: () => console,

        issueRows() {

            if (!this.currentCustomer.issues) {
                return []
            }
            if (!this.currentCustomer.issues.length) {
                return []
            }
            const filteredRows = this.currentCustomer.issues.filter(item => {
                return item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
            })

            return filteredRows.sort(function compare(a, b) {
                if(a.tagid != 3) {
                    if (a.tagid < b.tagid)
                        return -1;
                    if (a.tagid > b.tagid)
                        return 1;
                }
                return 0;
            }).sort(function compare(a, b) {
                if( (a.tagid == 3 || a.tagid == 4) && (b.tagid != 1) ) {
                    if (a.tagid < b.tagid)
                        return 1;
                    if (a.tagid > b.tagid)
                        return -1;
                }
                return 0;
            });
        },
    },
    methods: {
        ...mapActions([
            "makeActiveTab", "addTab", 'closeTab',
            "fetchCustomer", 'fetchMinMaintainers',
            'makeCurrentCar', 'makeCurrentIssue', 'makeCurrentMnt', 
            'updateCustomer', 'deleteCustomer'
        ]),

        onResize() {
            this.windowHeight = window.innerHeight;
            if (this.shrinked) {
                this.shrinkCols();
                this.mntShrinkCols();
            }
            else {
                this.fillWindowCols();
                this.mntFillWindowCols();
            }
        },
        fixCorruptedTime(t) {
            if (typeof t != "number") { // 
                let date = t.split("  ")
                let time = date[1].split(":")
                if (parseInt(time[1]) < 10) {
                    time[1] = "0" + time[1]
                }
                return `${date[0]} ${time[0]}:${time[1]}`
            }
        },

        getTime(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            const hrs = dt.getHours();
            const min = dt.getMinutes();
            return `${day}.${mth}.${yrs}  ${hrs}:${min}`;  
        },
        getDate(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}.${mth}.${yrs}`;  
        },
        cusUpdate(){
            if (this.$v.formResponses.$invalid) 
                return 0;

            this.updateCustomer({
                customerid: this.currentCustomerId,
                contactid: this.formResponses.contactid,
                invoiceid: this.formResponses.invoiceid,
                company_name: this.formResponses.company_name,
                ico: this.formResponses.ico,
                dic: this.formResponses.dic,
                invoice_street: this.sameAsAddress ? this.formResponses.street : this.formResponses.invoice_street,
                invoice_city: this.sameAsAddress ? this.formResponses.city : this.formResponses.invoice_city,
                invoice_zip: this.sameAsAddress ? this.formResponses.zip : this.formResponses.invoice_zip,
                street: this.formResponses.street,
                city: this.formResponses.city,
                zip: this.formResponses.zip,
            }).then(() => {
                this.$alerts.toast('Změny úspěšně zapsány', 'success')
                this.editTgl = false;
                this.fetchCustomer(this.currentCustomerId)
            }).catch(err => this.$alerts.message('Nastala chyba', err, 'error'))

        },

        cusDelete(){
            this.$alerts.confirmDelete('Opravdu chcete tento záznam smazat?', 'Smažou se s ním i jeho podzáznamy, tedy osoby, auta, úkony i operace.').then((result) => {
                if (result.value) {
                    this.deleteCustomer(this.currentCustomerId).then(() => {
                        this.closeTab(this.page.id);
                        this.$alerts.message('Záznam úspěšně smazán', '', 'success')
                    });
                }
            });
        },

        cellClick(event){
            if (!event.value)
                return null;
            
            if ( event.column.colId == 'name' ) {
                this.makeCurrentIssue(event.data.issueid);
            } else if ( event.column.colId == 'car_name' ) {
                this.makeCurrentCar(event.data.carid);
            } else if ( event.column.colId == 'company_name' ) {
                this.makeCurrentCus(event.data.customerid);
            } else if ( event.column.colId == 'fullname' ) {
                this.makeCurrentMnt(event.data.maintainerid);
            } else if ( event.column.colId == 'mnt_name' ) {
                this.makeCurrentMnt(event.data.maintainerid);
            } else if ( event.column.colId == 'last_issue' ) {
                this.makeCurrentIssue(event.data.last_issueid);
            } else if ( event.column.colId == 'next_issue' ) {
                this.makeCurrentIssue(event.data.next_issueid);
            }
        },
        onFilterTextBoxChanged() {
            this.gridOptions.api.setQuickFilter(this.filter);
        },
        onMntFilterTextBoxChanged() {
            this.mntGridOptions.api.setQuickFilter(this.filterMnts);
        },

        columnHide(id){
            // Set it to visible/invisible
            this.gridOptions.columnApi.setColumnVisible(this.columnDefs[id].field, this.hideCol[id]);

            if (this.shrinked)
                this.shrinkCols();
            else
                this.fillWindowCols();
        },

        mntColumnHide(id){
            // Set it to visible/invisible
            this.mntGridOptions.columnApi.setColumnVisible(this.mntColumnDefs[id].field, this.mntHideCol[id]);

            if (this.shrinked)
                this.mntShrinkCols();
            else
                this.mntFillWindowCols();
        },
        
        permitColChanges(){
            this.columnDefs.forEach((item,index) => {
                this.columnDefs[index].suppressMovable = !this.colChange;
                this.columnDefs[index].resizable = this.colChange;
            })
            this.gridOptions.api.setColumnDefs(this.columnDefs)
        },

        // Manipulation with width of columns
        fillWindowCols(){
            this.shrinked = false;
            //this.gridOptions.api.sizeColumnsToFit();
        },

        mntFillWindowCols(){
            this.shrinked = false;
            //this.mntGridOptions.api.sizeColumnsToFit();
        },

        shrinkCols(){
            this.shrinked = true;
            // Shrink columns
            var allColumnIds = [];
            this.gridOptions.columnApi.getAllColumns().forEach(function(column) {
                allColumnIds.push(column.colId);
            });
            this.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
        },

        mntShrinkCols(){
            this.mntShrinked = true;
            // Shrink columns
            var allColumnIds = [];
            this.mntGridOptions.columnApi.getAllColumns().forEach(function(column) {
                allColumnIds.push(column.colId);
            });
            this.mntGridOptions.columnApi.autoSizeColumns(allColumnIds, false);
        },

        // Methods for customer editting
        editCustomer(){
            // Set values for update
            this.formResponses.company_name = this.currentCustomer.company_name;
            this.formResponses.invoiceid = this.currentCustomer.invoiceid;
            this.formResponses.contactid = this.currentCustomer.contactid;
            this.formResponses.ico = this.currentCustomer.ico || '';
            this.formResponses.dic = this.currentCustomer.dic || '';
            this.formResponses.street = this.currentCustomer.street || '';
            this.formResponses.city = this.currentCustomer.city || '';
            this.formResponses.zip = this.currentCustomer.zip || '';
            if (
                this.currentCustomer.street == this.currentCustomer.invoice_street &&
                this.currentCustomer.city ==  this.currentCustomer.invoice_city &&
                this.currentCustomer.zip == this.currentCustomer.invoice_zip
            ) {
                this.sameAsAddress = true;
                this.formResponses.invoice_street = '';
                this.formResponses.invoice_city = '';
                this.formResponses.invoice_zip = '';
            } else {
                this.formResponses.invoice_street = this.currentCustomer.invoice_street || '';
                this.formResponses.invoice_city = this.currentCustomer.invoice_city || '';
                this.formResponses.invoice_zip = this.currentCustomer.invoice_zip || '';
            }
            

            this.editTgl = true;

            this.fetchMinMaintainers(this.currentCustomerId);
        }

    },

    beforeMount() {
        this.gridOptions = {
            context: {
                componentParent: this
            }
        };

        this.mntGridOptions = {
            context: {
                componentParent: this
            }
        };

        this.columnDefs = [
            {headerName: 'Název', field: 'name', sortable: true,  resizable: true, filter: true},
            {headerName: 'Auto', field: 'car_name', sortable: true,  resizable: true, filter: true},
            {headerName: 'Osoba', field: 'mnt_name', sortable: true,  resizable: true, filter: true},
            {headerName: 'Stav', field: 'tag_title', sortable: true,  resizable: true, filter: true},
            {headerName: 'Od', field: 'due_from', sortable: true,  resizable: true, filter: true},
            {headerName: 'Do', field: 'due_to', sortable: true,  resizable: true, filter: true},
            {headerName: 'Evidováno', field: 'added', sortable: true,  resizable: true, filter: true}
        ];

        this.mntColumnDefs = [
            {headerName: 'Jméno', field: 'fullname', sortable: true, filter: true, suppressMovable: true},
            {headerName: 'Poslední úkon', field: 'last_issue', sortable: true, filter: true, suppressMovable: true},
            {headerName: 'Následující úkon', field: 'next_issue', sortable: true, filter: true, suppressMovable: true},
            {headerName: 'Email', field: 'email', sortable: true, filter: true, suppressMovable: true},
            {headerName: 'Telefon', field: 'phone', sortable: true, suppressMovable: true},
        ];

        this.hideCol.forEach((item, index) => {
            this.columnDefs[index].hide = !item;
        });

        this.mntHideCol.forEach((item, index) => {
            this.mntColumnDefs[index].hide = !item;
        });

        this.rowData = this.currentCustomer.issues;
        this.mntRowData = this.currentCustomer.maintainers;
    },

    async created(){
        if (this.currentCustomerId){
            this.fetchCustomer(this.currentCustomerId).then(() => {
                this.formResponses.company_name = this.currentCustomer.company_name;
                this.formResponses.ico = this.currentCustomer.ico;
                this.oldinvoiceid = this.currentCustomer.invoiceid;
                this.rowData = this.currentCustomer.issues;
                this.mntRowData = this.currentCustomer.maintainers;

                if (Array.isArray(this.rowData))
                this.rowData.forEach((item, index) => {
                    this.rowData[index].added = this.getTime(item.added);
                    if (item.due_from > 0) this.rowData[index].due_from = this.getTime(item.due_from);
                    if (item.due_to > 0) this.rowData[index].due_to = this.getTime(item.due_to);
                })

                this.page = this.getAllPages.filter(page => page.name == name)[0]
                this.addTab({...this.page, lng_name: this.currentCustomer.company_name});
                this.makeActiveTab(this.page);

                this.fillWindowCols()
            });
        } else {
            this.closeTab(8)
            router.push({ name: 'Customers' })
        }
    },
    mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    }
}
</script>

<style scoped>
a {
    cursor: pointer;
}
</style>

<style>
.ag-cell-focus,.ag-cell-no-focus{
  border:none !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus{
  border:none !important;
  outline: none;
}
</style>
